// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2023. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          App.tsx
//
// Written By:          Beau Anderson
// ------------------------------ tabstop = 2 ----------------------------------

import { CircularProgress } from "@mui/material";
import { createBrowserHistory } from "history";
import { Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Redirect, Route, Router, Switch, useLocation } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import GlobalLoading from "./Components/GlobalLoading";
import { BackButtonContextProvider } from "./Contexts/BackButtonContext";
import { LocalizerContextProvider } from "./Contexts/LocalizerContext";
import { MatomoTrackerContextProvider } from "./Contexts/MatomoTrackerContextProvider";
import { useSession } from "./hooks/Auth/useSession";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { GalaxyRoutes, isRouteAvailable } from "./Routes/GalaxyRoutes";
import { ProductAnnouncements } from "./Components/ProductAnnouncements";
import * as serviceWorker from "./serviceWorker";

// Important: only place where we import css files (to prevent duplicate <style> tags)
// eslint-disable-next-line no-restricted-imports
import "./index.scss";
import ErrorBoundary from "./Components/ErrorBoundary";
import ErrorFallback from "./Components/ErrorBoundary/fallback";

// UX: Scroll to top of page on navigation route -> https://reactrouter.com/web/guides/scroll-restoration
const ScrollToTop = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

function App(): JSX.Element {
  const { profile } = useSession();

  return (
    <>
      {profile ? <Header /> : null}
      <div
        className={`body ${profile ? "" : "no-padding"}` /* remove padding for headerless pages */}
      >
        <Suspense
          fallback={
            <div className="global-loading-outer fade-in">
              <div className="global-loading-inner">
                <CircularProgress size={"6em"} />
              </div>
            </div>
          }
        >
          <Switch>
            {Object.values(GalaxyRoutes)
              .filter((route) => {
                return isRouteAvailable(route, profile);
              })
              .map(({ path, component, exact }) => (
                <Route exact={exact ?? true} key={path} path={path} component={component} />
              ))}
            <Redirect
              key={"fallback-redirect"}
              from={"/*"}
              to={profile ? GalaxyRoutes.DASHBOARD.path : GalaxyRoutes.SIGN_IN.path}
            />
          </Switch>
        </Suspense>
      </div>
      {profile ? <Footer /> : null}
      <ProductAnnouncements />
    </>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Router history={createBrowserHistory()}>
    <LocalizerContextProvider>
      <BackButtonContextProvider>
        <MatomoTrackerContextProvider>
          <ScrollToTop>
            <GlobalLoading>
              <>
                <ErrorBoundary fallback={<ErrorFallback />}>
                  <App />
                </ErrorBoundary>
                <ToastContainer
                  transition={Slide}
                  position="bottom-left"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </>
            </GlobalLoading>
          </ScrollToTop>
        </MatomoTrackerContextProvider>
      </BackButtonContextProvider>
    </LocalizerContextProvider>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Polyfill `setImmediate`: https://developer.mozilla.org/en-US/docs/Web/API/Window/setImmediate
// @ts-expect-error
window.setImmediate =
  typeof setImmediate === "function"
    ? window.setImmediate
    : (fn): NodeJS.Timeout => setTimeout(fn, 0);
