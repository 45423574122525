// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2023. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          Toasts
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import { ToastContent, toast } from "react-toastify";
import { FeedbackForm } from "../FeedbackForm";
import { UseInstallations } from "../../hooks/Installations/useInstallations";
import { IgnoreSensorData, SensorData } from "@rfcode/galaxy-model";
import { Link } from "react-router-dom";
import { sensorTypeFormatted } from "../../Utils/bluetoothSensorUtils";
import { CircularProgress } from "@mui/material";

const FEEDBACK_TOAST_ID = "feedback-toast";

export function showFeedbackToast({ prompt }: { prompt: string }): void {
  toast(
    <FeedbackForm
      promptText={prompt}
      startCompressed={true}
      onSubmit={(): void => toast.dismiss(FEEDBACK_TOAST_ID)}
    />,
    {
      className: "feedback-toast"
      , autoClose: false
      , closeOnClick: false
      , draggable: false
      , toastId: FEEDBACK_TOAST_ID
    }
  );
}

export const sensorPairingToast = (
  installationId: string,
  sensor: SensorData,
  useInstallations: UseInstallations
): void => {
  const serialNumber = sensor.serial_number.toUpperCase();
  const uppercaseType = sensorTypeFormatted(sensor.type);
  const lowercaseType = uppercaseType.toLowerCase();

  // Apply image for pairing toast based on sensor type
  let pairingImg = "";
  if (sensor.type === "FLUID") {
    pairingImg = "fluid-leak-sensor.png";
  }

  // Keep track of if a button is pressed
  let validating = false;

  // Content for toast
  const toastContent: ToastContent = (
    <div className="pairing-toast-container">
      <img
        className="pairing-icon"
        alt="Sensor Icon"
        src={`${process.env.PUBLIC_URL}/${pairingImg}`}
      />
      <div className="pairing-title">{`A ${lowercaseType} sensor is ready to pair`}</div>
      <div>{`The serial number is ${serialNumber}`}</div>
      <div>
        <button
          className="btn pair-button"
          onClick={(): void => {
            void subscribeSensor();
          }}
        >
          {`Pair ${uppercaseType} Sensor`}
        </button>
      </div>
      <div>
        <button
          className="pairing-ignore"
          onClick={(): void => {
            void ignoreSensor();
          }}
        >
          Ignore Sensor
        </button>
      </div>
    </div>
  );

  // Alternative toast content while validating ignore/subscribe
  const toastContentDisabled: ToastContent = (
    <div className="pairing-toast-container">
      <img
        className="pairing-icon"
        alt="Sensor Icon"
        src={`${process.env.PUBLIC_URL}/${pairingImg}`}
      />
      <div className="pairing-title">{`A ${lowercaseType} sensor is ready to pair`}</div>
      <div>{`The serial number is ${serialNumber}`}</div>
      <div>
        <button className="btn pair-button-disabled submitting">
          <CircularProgress size="1.5em" color="inherit" />
        </button>
      </div>
      <div>
        <button className="pairing-ignore-disabled">Ignore Sensor</button>
      </div>
    </div>
  );

  const ignoreSensor = async (): Promise<void> => {
    if (validating) {
      return;
    }
    toast.update("pairing-toast", {
      render: toastContentDisabled
    });
    validating = true;
    try {
      const ignoredSensors = await useInstallations.ignoreSensors(installationId, [sensor]);
      if (
        ignoredSensors?.find(
          (ignored) =>
            sensor.model === ignored.model && sensor.serial_number === ignored.serial_number
        )
      ) {
        informSuccess(`${uppercaseType} Sensor Ignored Successfully`);
        toast.dismiss("pairing-toast");
      } else {
        throw new Error("Sensor was not successfully added to the ignore list");
      }
    } catch (e) {
      console.error(e);
      informError(`${uppercaseType} Sensor Ignoring Failed`);
    } finally {
      validating = false;
      toast.update("pairing-toast", {
        render: toastContent
      });
    }
  };

  const subscribeSensor = async (): Promise<void> => {
    if (validating) {
      return;
    }
    toast.update("pairing-toast", {
      render: toastContentDisabled
    });
    validating = true;
    try {
      const subscribedSensors = await useInstallations.subscribeSensors(installationId, [sensor]);
      if (
        subscribedSensors?.find(
          (subscribed) =>
            sensor.model === subscribed.model && sensor.serial_number === subscribed.serial_number
        )
      ) {
        informSuccess(`${uppercaseType} Sensor Paired Successfully`);
        toast.dismiss("pairing-toast");
      } else {
        throw new Error("Sensor was not successfully added to the subscriber list");
      }
    } catch (e) {
      console.error(e);
      informError(`${uppercaseType} Sensor Pairing Failed`);
    } finally {
      validating = false;
      toast.update("pairing-toast", {
        render: toastContent
      });
    }
  };

  if (toast.isActive("pairing-toast")) {
    toast.update("pairing-toast", { render: toastContent });
  }

  // Does nothing if toast with toastId already exists
  toast(toastContent, {
    className: "pairing-toast"
    , autoClose: false
    , closeOnClick: false
    , draggable: false
    , toastId: "pairing-toast"
  });
};

export const pairingRedirectToast = (
  installationId: string,
  useInstallations: UseInstallations,
  sensors: SensorData[],
  path: string
): void => {
  // Keep track of if a button is pressed
  let validating = false;

  // Make a copy of the sensors and strip down to only what is needed for ignoring
  let sensorsToIgnore: IgnoreSensorData[] = sensors.map((sensor) => ({
    model: sensor.model
    , serial_number: sensor.serial_number
  }));

  // Content for toast
  const toastContent: ToastContent = (
    <div className="pairing-toast-container">
      <img
        className="pairing-icon"
        alt="Sensor Icon"
        src={`${process.env.PUBLIC_URL}/fluid-leak-sensor.png`}
      />
      <div className="pairing-title">{sensorsToIgnore.length} sensors are ready to pair</div>
      <div>Pair sensors from the device list</div>
      <div className="redirect-container">
        <Link
          to={path}
          className="btn redirect-button"
          onClick={(): void => {
            toast.dismiss("pairing-toast");
          }}
        >
          Device List
        </Link>
      </div>
      <div>
        <button
          className="pairing-ignore"
          onClick={(): void => {
            void ignoreSensors();
          }}
        >
          Ignore Sensors
        </button>
      </div>
    </div>
  );

  // Alternative toast content while validating ignore/subscribe
  const toastContentDisabled: ToastContent = (
    <div className="pairing-toast-container">
      <img
        className="pairing-icon"
        alt="Sensor Icon"
        src={`${process.env.PUBLIC_URL}/fluid-leak-sensor.png`}
      />
      <div className="pairing-title">{sensorsToIgnore.length} sensors are ready to pair</div>
      <div>Pair sensors from the device list</div>
      <button className="btn pair-button-disabled submitting">
        <CircularProgress size="1.5em" color="inherit" />
      </button>
      <div>
        <button className="pairing-ignore-disabled">Ignore Sensors</button>
      </div>
    </div>
  );

  const ignoreSensors = async (): Promise<void> => {
    if (validating) {
      return;
    }
    toast.update("pairing-toast", {
      render: toastContentDisabled
    });
    validating = true;
    try {
      const ignoredSensors = await useInstallations.ignoreSensors(installationId, sensors);
      // Filter out all properly ignored sensors from the list of sensors
      sensorsToIgnore = sensorsToIgnore.filter(
        (sensor) =>
          !ignoredSensors?.some(
            (ignoredSensor) =>
              sensor.model === ignoredSensor.model &&
              sensor.serial_number === ignoredSensor.serial_number
          )
      );
      // Check if all sensors were properly ignored
      if (sensorsToIgnore.length === 0) {
        informSuccess("Sensors Ignored Successfully");
        toast.dismiss("pairing-toast");
      } else {
        throw new Error("One or more sensors not successfully added to the ignore list");
      }
    } catch (e) {
      console.error(e);
      informError("Sensor Ignoring Failed");
    } finally {
      validating = false;
      toast.update("pairing-toast", {
        render: toastContent
      });
    }
  };

  if (toast.isActive("pairing-toast")) {
    toast.update("pairing-toast", { render: toastContent });
  }

  // Does nothing if toast with toastId already exists
  toast(toastContent, {
    className: "pairing-toast"
    , autoClose: false
    , closeOnClick: false
    , draggable: false
    , toastId: "pairing-toast"
  });
};

export const informSuccess = (message: string): void => {
  toast.success(({ closeToast }) => (
    <div>
      <span className="material-icons success-icon">check_circle</span>
      <div className="message">{message}</div>
    </div>
  ));
};

export const informError = (message?: string): void => {
  toast.error(
    ({ closeToast }) => (
      <div>
        <span className="material-icons error-icon">error</span>
        <div className="message">{message ?? "Something went wrong"}</div>
      </div>
    ),
    { pauseOnFocusLoss: true }
  );
};
