// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2024. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Component Name:      Localization
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import { MetricId, UserProfile } from "@rfcode/galaxy-model";

export interface LocalizerPreferences {
  useCelsius: boolean;
}

// We'll flesh this out in the far future
export function getLocalizerPreferences(
  profile: UserProfile | undefined | null
): LocalizerPreferences {
  return Localizer.DEFAULTS;
}

/**
 * Localizer is a helper class designed to convert to & from end-user locales, e.g. temperature
 * in celsius or fahrenheit.
 */
export class Localizer {
  static DEFAULTS: LocalizerPreferences = {
    useCelsius: false
  };

  static DEFAULT_PRECISION = 1;

  private preferences: LocalizerPreferences;

  constructor(preferences?: LocalizerPreferences) {
    this.preferences = preferences ?? Localizer.DEFAULTS;
  }

  /**
   * Generates the units suffix for a temperature reading depending on the user's localization preferences.
   * @returns an non-breaking space character followed by °F or °C
   */
  getTemperatureSuffix = (): string => {
    if (this.preferences.useCelsius) {
      return "\u00A0°C";
    } else {
      return "\u00A0°F";
    }
  };

  /**
   * Converts the server-side value into a client-side value that respects the user's localization preferences.
   */
  localizeTemperature = (temperature: number): number => {
    if (this.preferences.useCelsius) {
      return temperature;
    }
    return (temperature * 9) / 5 + 32;
  };

  /**
   * Converts the client-side value into a server-side value that respects the user's localization preferences.
   */
  normalizeTemperature = (temperature: number): number => {
    if (this.preferences.useCelsius) {
      return temperature;
    }
    return ((temperature - 32) * 5) / 9;
  };

  formatReading = (metricId: MetricId, value: number | undefined, showUnit = true): string => {
    const localized = this.localizeReading(metricId, value);
    if (localized == null) {
      return "";
    } else if (metricId.toLowerCase().includes("temperature")) {
      return (
        localized.toFixed(Localizer.DEFAULT_PRECISION) +
        (showUnit ? this.getTemperatureSuffix() : "")
      );
    } else if (metricId.toLowerCase().includes("humidity")) {
      return localized.toFixed(Localizer.DEFAULT_PRECISION) + (showUnit ? "% RH" : "");
    } else {
      return localized.toFixed(Localizer.DEFAULT_PRECISION);
    }
  };

  localizeReading = (metricId: MetricId, value: number | undefined): number | undefined => {
    if (value == null) {
      return value;
    } else if (metricId.toLowerCase().includes("temperature")) {
      return parseFloat(this.localizeTemperature(value).toFixed(Localizer.DEFAULT_PRECISION));
    } else {
      return parseFloat(value.toFixed(Localizer.DEFAULT_PRECISION));
    }
  };
}
