// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          api/v1/auth
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import {
  LoginRequest,
  LoginTenantChallenge,
  ChangePasswordRequest,
  WithPasswordPolicy,
  ForgotPasswordRequest,
  ResetPasswordPreflight,
  ForgotPasswordToken,
  ResetPasswordRequest,
  FinishSignupPreflight,
  UserInviteToken,
  TenantInviteToken,
  FinishSignupRequest
} from "@rfcode/galaxy-model";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { merge } from "lodash";
import { COMMON_HEADERS } from "../v1/common";

const COMMON_AUTH_HEADERS = {
  ...COMMON_HEADERS
  , "cache-control": "no-cache"
};

export const logout = async (options: AxiosRequestConfig = {}): Promise<AxiosResponse<void>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "POST"
    , url: "/api/v1/auth/logout"
    , headers: COMMON_AUTH_HEADERS
  };
  return axios(merge(requestConfig, options));
};

export const login = async (
  data: LoginRequest,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<null | LoginTenantChallenge>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "POST"
    , url: "/api/v1/auth/login"
    , headers: COMMON_AUTH_HEADERS
    , data: {
      ...data
      , password: `base64:${btoa(data.password)}`
    }
  };
  return axios(merge(requestConfig, options));
};

export const changePassword = async (
  data: ChangePasswordRequest,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<void>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "POST"
    , url: "/api/v1/auth/change-password"
    , headers: COMMON_AUTH_HEADERS
    , data: {
      oldPassword: `base64:${btoa(data.oldPassword)}`
      , newPassword: `base64:${btoa(data.newPassword)}`
    }
  };
  return axios(merge(requestConfig, options));
};

export const changePasswordPreflight = async (
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<WithPasswordPolicy>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "GET"
    , url: "/api/v1/auth/change-password"
    , headers: COMMON_AUTH_HEADERS
  };
  return axios(merge(requestConfig, options));
};

export const forgotPassword = async (
  data: ForgotPasswordRequest,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<null | LoginTenantChallenge>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "POST"
    , url: "/api/v1/auth/forgot-password"
    , headers: COMMON_AUTH_HEADERS
    , data
  };
  return axios(merge(requestConfig, options));
};

export const resetPasswordPreflight = async (
  { token }: ResetPasswordPreflight,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<ForgotPasswordToken>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "GET"
    , url: `/api/v1/auth/reset-password/${token}`
    , headers: COMMON_AUTH_HEADERS
  };
  return axios(merge(requestConfig, options));
};

export const resetPassword = async (
  { token, newPassword }: ResetPasswordRequest,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<void>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "POST"
    , url: `/api/v1/auth/reset-password/${token}`
    , headers: COMMON_AUTH_HEADERS
    , data: {
      newPassword: `base64:${btoa(newPassword)}`
    }
  };
  return axios(merge(requestConfig, options));
};

export const finishSignupPreflight = async (
  { token }: FinishSignupPreflight,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<UserInviteToken | TenantInviteToken>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "GET"
    , url: `/api/v1/auth/finish-signup/${token}`
    , headers: COMMON_AUTH_HEADERS
  };
  return axios(merge(requestConfig, options));
};

export const finishSignup = async (
  { token, newPassword }: FinishSignupRequest,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<void>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "POST"
    , url: `/api/v1/auth/finish-signup/${token}`
    , headers: COMMON_AUTH_HEADERS
    , data: {
      newPassword: `base64:${btoa(newPassword)}`
    }
  };
  return axios(merge(requestConfig, options));
};
