// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2021. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Component Name:      LocalizerContext
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import React, { useContext, useEffect, useState } from "react";
import { useSession } from "../hooks/Auth/useSession";
import { getLocalizerPreferences, Localizer } from "../Localization";

export const LocalizerContext = React.createContext<Localizer>(new Localizer());

/**
 * LocalizerContext provides detail-free access to Localizer helper methods.
 *
 * ```
 * // Usage:
 * const localizer = useContext(LocalizerContext);
 * const converted = `${localizer.localizeTemperature(temperature).toFixed(1)}${localizer.getTemperatureSuffix()}`;
 * ```
 */
export const LocalizerContextProvider = (props): JSX.Element => {
  const { profile } = useSession();

  // hook into localization capabilities
  const [localizerPreferences, setLocalizerPreferences] = useState(
    getLocalizerPreferences(profile)
  );
  const [localizer, setLocalizer] = useState<Localizer>(new Localizer(localizerPreferences));
  useEffect(() => {
    setLocalizerPreferences(getLocalizerPreferences(profile));
  }, [profile]);
  // two separate useEffects so we can quickly toggle between the two during development/testing!
  useEffect(() => {
    setLocalizer(new Localizer(localizerPreferences));
  }, [localizerPreferences]);

  return <LocalizerContext.Provider value={localizer}>{props.children}</LocalizerContext.Provider>;
};
