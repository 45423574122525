//------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2023. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          api/v1/logstore
//
// Written By:          Sushil Joshi
//------------------------------ tabstop = 2 ----------------------------------

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { merge } from "lodash";
import { COMMON_HEADERS } from "./common";

export const BaseLoggingUrl = "/api/v1/uilog";
const MAX_MSG_LENGTH = 65536; // clip the error message to this length

const logErrorToService = async (
  msg: string,
  error?: Error,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<void>> => {
  const clippedMessage = ((error ? error.stack + "\nRemaining msg\n" : "") + msg).slice(
    0,
    MAX_MSG_LENGTH
  );
  const requestConfig: AxiosRequestConfig = {
    method: "POST"
    , url: BaseLoggingUrl
    , headers: {
      ...COMMON_HEADERS
    }
    , data: {
      msg: clippedMessage
    }
  };
  return axios(merge(requestConfig, options));
};

export default logErrorToService;
