// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2021-2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          GlobalLoading
//
// Written By:          Beau Anderson
// ------------------------------ tabstop = 2 ----------------------------------

import { CircularProgress } from "@mui/material";
import React from "react";
import { useSession } from "../../hooks/Auth/useSession";

const GlobalLoading = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isLoading, isError } = useSession();

  // don't render anything if the backend server happens to be down (i.e. 500 errors)
  if (!isLoading && !isError) {
    return children;
  }

  return (
    <div className="global-loading-outer fade-in">
      <div className="global-loading-inner">
        <CircularProgress size={"6em"} />
      </div>
    </div>
  );
};

export default GlobalLoading;
