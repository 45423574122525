//------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          api/v1/users
//
// Written By:          Patrick Stewart
//------------------------------ tabstop = 2 ----------------------------------

import { UserProfile, UUID } from "@rfcode/galaxy-model";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { merge } from "lodash";
import { COMMON_HEADERS, validateStatus } from "./common";

export const BaseUserProfilesUrl = "/api/v1/users";

export const getMyProfile = async (
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<UserProfile>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "GET"
    , url: `${BaseUserProfilesUrl}/my-profile`
    , headers: {
      ...COMMON_HEADERS
      , "cache-control": "no-cache"
    }
    , validateStatus: function (status) {
      // treat 401 as a special-case "success" - redirect the user to sign-in
      return status === 200 || status === 401;
    }
  };
  return axios(merge(requestConfig, options));
};

export const updateMyProfile = async (
  data: Pick<UserProfile, "displayName">,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<UserProfile>> => {
  if (!data.displayName) {
    delete data.displayName;
  }
  const requestConfig: AxiosRequestConfig = {
    method: "PATCH"
    , url: `${BaseUserProfilesUrl}/my-profile`
    , headers: {
      ...COMMON_HEADERS
      , "cache-control": "no-cache"
    }
    , data
  };
  return axios(merge(requestConfig, options));
};

export const deleteMyProfile = async (
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<void>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "DELETE"
    , url: `${BaseUserProfilesUrl}/my-profile`
    , headers: {
      ...COMMON_HEADERS
      , "cache-control": "no-cache"
    }
  };
  return axios(merge(requestConfig, options));
};

export const getAllUserProfiles = async (
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<UserProfile[]>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "GET"
    , url: BaseUserProfilesUrl
    , headers: {
      ...COMMON_HEADERS
    }
    , validateStatus
  };
  return axios(merge(requestConfig, options));
};

export const getUserProfileById = async (
  id: UUID,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<UserProfile | null>> => {
  const requestConfig: AxiosRequestConfig = {
    method: "GET"
    , url: `${BaseUserProfilesUrl}/${id}`
    , headers: {
      ...COMMON_HEADERS
    }
    , validateStatus
  };
  return axios(merge(requestConfig, options));
};

export const updateUserProfile = async (
  request: Partial<UserProfile>,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<UserProfile>> => {
  if (!request.displayName) {
    delete request.displayName;
  }
  const requestConfig: AxiosRequestConfig = {
    method: "PATCH"
    , url: `${BaseUserProfilesUrl}/${request.guid}`
    , headers: {
      ...COMMON_HEADERS
    }
    , data: request
  };
  return axios(merge(requestConfig, options));
};

export const createUserProfile = async (
  request: Partial<UserProfile>,
  options: AxiosRequestConfig = {}
): Promise<AxiosResponse<UserProfile>> => {
  if (!request.displayName) {
    delete request.displayName;
  }
  const requestConfig: AxiosRequestConfig = {
    method: "POST"
    , url: BaseUserProfilesUrl
    , headers: {
      ...COMMON_HEADERS
    }
    , data: request
  };
  return axios(merge(requestConfig, options));
};

export const deleteUserProfile = async (
  id: UUID,
  options: AxiosRequestConfig = {}
): Promise<void> => {
  const requestConfig: AxiosRequestConfig = {
    method: "DELETE"
    , url: `${BaseUserProfilesUrl}/${id}`
    , headers: {
      ...COMMON_HEADERS
    }
  };
  return axios(merge(requestConfig, options)).then(() => {
    /* no-op */
  }, console.error);
};
