// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          Header
//
// Written By:          Beau Anderson
// ------------------------------ tabstop = 2 ----------------------------------

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { MatomoTrackerContext } from "../../Contexts/MatomoTrackerContextProvider";
import { useSession } from "../../hooks/Auth/useSession";
import { GalaxyRoutes, isRouteAvailable } from "../../Routes/GalaxyRoutes";
import HeaderButton from "./HeaderButton";
import { showFeedbackToast } from "../../Components/Toasts";

const Header = (): JSX.Element => {
  const history = useHistory();
  const { profile, logout } = useSession();
  const { trackEvent } = useContext(MatomoTrackerContext);

  const [isProfileDialogOpen, setProfileDialogOpen] = useState(false);
  const profileModalRef = useRef<HTMLDivElement>(null);

  const handleSignOut = (): void => {
    trackEvent({
      category: "Authentication"
      , name: "Authentication"
      , action: "Sign Out"
    });
    void logout();
  };

  const renderProfileDialog = (): JSX.Element => (
    <div ref={profileModalRef} className="profile-popover">
      <span className="account-icon material-icons">account_circle</span>
      <div className="user-name">{profile?.email}</div>
      <Link
        id="edit-profile"
        to={GalaxyRoutes.MY_PROFILE.path}
        className="edit-profile"
        onClick={(): void => setProfileDialogOpen(false)}
      >
        Edit my profile
      </Link>
      <button onClick={handleSignOut} className="btn btn-outline sign-out">
        Sign out
      </button>
    </div>
  );

  const routes = Object.values(GalaxyRoutes)
    .filter((route) => route?.component != null && route?.sortOrder != null)
    .filter((route) => {
      return isRouteAvailable(route, profile);
    })
    .map((route) => {
      return {
        ...route
        , active: history.location.pathname.includes(route.path)
      };
    })
    .sort((left, right) => (left.sortOrder || 0) - (right.sortOrder || 0));

  const activeRoute = Object.keys(GalaxyRoutes)
    .filter((key) => history.location.pathname === GalaxyRoutes[key].path)
    .map((key) => GalaxyRoutes[key]);

  const dismissProfileDialogOnExternalClick = React.useCallback<EventListener>(
    (event): void => {
      // GAL-2557: prevent UI crash by defensively checking for `includes` existence
      if (event.target instanceof Element && !!event.target.className.includes) {
        if (
          event.target.id === "edit-profile" ||
          event.target.className.includes("openProfileDialog") ||
          event.target.className.includes("cancelProfileDialog") ||
          event.target.id.includes("profile-button") ||
          event.target.parentElement?.id.includes("profile-button")
        ) {
          // click events on these targets are already have dedicated handlers
          return;
        }
      }
      // if click target is NOT inside dialog box, close the dialog (warn: NOT `else if`)
      if (event.target instanceof Node && !profileModalRef.current?.contains(event.target)) {
        setProfileDialogOpen((value) => !value);
      }
    },
    [profileModalRef]
  );

  useEffect(() => {
    if (isProfileDialogOpen) {
      window.addEventListener("click", dismissProfileDialogOnExternalClick);
    }
    return (): void => {
      window.removeEventListener("click", dismissProfileDialogOnExternalClick);
    };
  }, [dismissProfileDialogOnExternalClick, isProfileDialogOpen]);

  return (
    <div
      className={`nav-header ${
        activeRoute.length > 0 ? activeRoute[activeRoute.length - 1].navHeaderClass : ""
      }`}
    >
      <header>
        <HeaderButton></HeaderButton>
        <nav>
          <div className="center">
            {routes.map((info) => (
              <Link key={info.path} to={info.path} className={info.active ? "active" : ""}>
                {info.materialIcon ? (
                  <span className={info.active ? "material-icons" : "material-icons-outlined"}>
                    {info.materialIcon}
                  </span>
                ) : null}
                <span className="nav-label">
                  {info.label}
                  {info.labelSuffix ? <span className="shortener">{info.labelSuffix}</span> : null}
                </span>
              </Link>
            ))}
          </div>
        </nav>
        <div className="button-container">
          <button
            id="feedback-button"
            className="auxiliary-button"
            title="Submit feedback"
            onClick={(): void => showFeedbackToast({ prompt: "How are you liking Sentry?" })}
          >
            <span className="material-icons-outlined">feedback</span>
            <div className="label">Feedback</div>
          </button>
          <button
            id="updates-button"
            className="auxiliary-button"
            title="View Sentry product updates"
            onClick={(): void => {
              // GAL-3634: window.open to use dedicated browser (maybe)
              window.open(GalaxyRoutes.UPDATES.path, "_blank", "noreferrer noopener");
            }}
          >
            <span className="material-icons-outlined">campaign</span>
            <div className="label">Updates</div>
          </button>
          <button
            id="help-button"
            className="auxiliary-button"
            title="View Sentry help documentation"
            onClick={(): void => {
              // GAL-3634: window.open to use dedicated browser (maybe)
              window.open(GalaxyRoutes.HELP.path, "_blank", "noreferrer noopener");
            }}
          >
            <span className="material-icons-outlined">help</span>
            <div className="label">Help</div>
          </button>
          <button
            id="profile-button"
            className="auxiliary-button"
            title="Profile"
            onClick={(): void => setProfileDialogOpen(!isProfileDialogOpen)}
          >
            {isProfileDialogOpen ? (
              <span className="material-icons cancelProfileDialog">cancel</span>
            ) : (
              <span className="material-icons openProfileDialog">account_circle</span>
            )}
            <div className="label" onClick={(): void => setProfileDialogOpen(!isProfileDialogOpen)}>
              {profile?.displayName?.trim() || profile?.email || "Profile"}
            </div>
          </button>
          {isProfileDialogOpen ? renderProfileDialog() : null}
        </div>
      </header>
    </div>
  );
};

export default Header;
