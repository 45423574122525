// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2023. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          HeaderButton
//
// Written By:          Adam Kawula
// ------------------------------ tabstop = 2 ----------------------------------

import { useContext } from "react";
import { BackButtonContext } from "../../Contexts/BackButtonContext";

/**
 * @see BackButtonContext
 */
const HeaderButton = (): JSX.Element => {
  const { showBackButton, getBackLabel, goBack, goToDashboard } = useContext(BackButtonContext);

  if (showBackButton) {
    return (
      <button className="header-button" onClick={(): void => goBack()} type="button">
        <span className="material-icons">arrow_back</span>
        <span className="label">{getBackLabel()} </span>
      </button>
    );
  } else {
    return (
      <button className="logo" onClick={(): void => goToDashboard()}>
        SENTRY
      </button>
    );
  }
};

export default HeaderButton;
