// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2024. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          ProductAnnouncements
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import React from "react";
import { ToastContent, toast } from "react-toastify";
import { useSession } from "../../hooks/Auth/useSession";
import { GalaxyRoutes } from "../../Routes/GalaxyRoutes";
import announcements from "./announcements.json";

export interface Announcement {
  title: string;
  id: string;
}

export const ProductAnnouncements = (): JSX.Element => {
  const { profile } = useSession();
  React.useEffect(() => {
    // GAL-3663: don't display product announcements in demo tenant (staging)
    const demoTenant = "20363c32-afcb-4373-9797-89b3e6a7b98a";
    if (profile && profile.tenant !== demoTenant) {
      showLatestAnnouncement();
    }
  }, [profile]);
  return <></>;
};

export function acknowledgeAnnouncements(): void {
  // note: using client side localStorage for the product announcements MVP
  // a more complicated impl could use a separate API+DB entry to track this
  // another alternative might be to crawl sentryhelp.rfcode.com/blog for the most recent entry
  localStorage.setItem(getStorageKey(), announcements[announcements.length - 1].id);
}

export function showLatestAnnouncement(): void {
  const latestAnnouncement = announcements[announcements.length - 1];
  // coerce falsy to null so comparison works
  const stored = localStorage.getItem(getStorageKey()) || null;
  const latest = latestAnnouncement?.id || null;
  if (stored !== latest) {
    showAnnouncement(latestAnnouncement);
  }
}

function getStorageKey(): string {
  return "latestProductAnnouncement";
}

const showAnnouncement = (announcement: Announcement): void => {
  const toastContent: ToastContent = (
    <>
      <div className="icon">
        <span className="material-icons-outlined">campaign</span>
      </div>
      <div className="title">{announcement.title}</div>
      <button
        className="btn btn-primary"
        onClick={(): void => {
          toast.dismiss(toastId);
          acknowledgeAnnouncements();
          // GAL-3634: window.open to use dedicated browser (maybe)
          window.open(
            `${GalaxyRoutes.UPDATES.path}/${announcement.id}`,
            "_blank",
            "noreferrer noopener"
          );
        }}
      >
        Find out more
      </button>
    </>
  );

  toast(toastContent, {
    className: "announcement-toast"
    , autoClose: false
    , closeOnClick: false
    , draggable: false
    , toastId
    , onClose: (): void => acknowledgeAnnouncements()
  });
};

const toastId = "announcement-toast";
