// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2022-2024. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Component Name:      ButtonWithLoaderFormik
//
// Written By:          Richard Salcedo
// ------------------------------ tabstop = 2 ----------------------------------

import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { FormikValues, useFormik } from "formik";

export interface ButtonWithLoaderFormikProps<T extends FormikValues> {
  text: string;
  formik: ReturnType<typeof useFormik<T>>;
  iconStart?: JSX.Element;
  iconEnd?: JSX.Element;
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}

/**
 * - Replaces any icons & text with a circular loader while submitting
 * - Automatically enables/disabled button based on formik form status
 *
 * @note Passing in a material icon works great. If you want to wrap the icon and style it with color, I found that adding "display: flex"
 * to the wrapper styling was necessary to get the icon to align.
 */
function ButtonWithLoaderFormik<T extends FormikValues>(
  props: ButtonWithLoaderFormikProps<T>
): React.ReactElement {
  const { formik, isDisabled, isLoading } = props;

  let buttonWithLoaderClass = "button-with-loader";

  if (props.className) {
    buttonWithLoaderClass += ` ${props.className}`;
  }

  if (formik.isSubmitting || !!isLoading) {
    buttonWithLoaderClass += " button-with-loader-loading";
  }

  const disableButton =
    formik.isSubmitting || (formik.submitCount > 0 && !formik.isValid) || !!isDisabled;

  return (
    <button className={buttonWithLoaderClass} type="submit" disabled={disableButton}>
      <div className="button-with-loader-items">
        {formik.isSubmitting ? (
          <div className="button-with-loader-loader">
            <CircularProgress size="1.5em" color="inherit" />
          </div>
        ) : (
          <>
            {props.iconStart && <div className="button-with-loader-icon">{props.iconStart}</div>}
            <span>{props.text}</span>
            {props.iconEnd && <div className="button-with-loader-icon">{props.iconEnd}</div>}
          </>
        )}
      </div>
    </button>
  );
}

export default ButtonWithLoaderFormik;
