// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2021-2023. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          withTracking
//
// Written By:          Sushil Joshi
// ------------------------------ tabstop = 2 ----------------------------------

import React from "react";
import { useRouteMatch } from "react-router-dom";
import { MatomoTrackerContext } from "../Contexts/MatomoTrackerContextProvider";

/**
 * A higher order component that wraps a page component and adds functionality to
 * track the page with matomo. Only functional component is supported by for now. If
 * we write pages as traditional component and we need to track the page, this
 * can be modified to support such components.
 *
 * @param PageComponent A functional component that gets displayed for a galaxy path
 * @returns A higher order component that triggers a matomo page view event when mounted to DOM
 */
export const withTracking = (PageComponent: React.FC): React.FC => {
  const TrackedPage: React.FC = (props): JSX.Element => {
    const routeMatch = useRouteMatch();
    const { trackPageView } = React.useContext(MatomoTrackerContext);

    React.useEffect(() => {
      trackPageView({
        documentTitle: document.title
        , currentUrl: document.location.origin + hidePersonalData(routeMatch.url, routeMatch.path)
      });
    }, [routeMatch, trackPageView]);

    return <PageComponent {...props} />;
  };

  return TrackedPage;
};

const hidePersonalData = (matchedUrl: string, matchedPattern: string): string => {
  if (matchedPattern.startsWith("/admin/users/:id")) {
    return matchedUrl.split("/").slice(0, 3).join("/") + "/email@masked";
  }
  return matchedUrl;
};
