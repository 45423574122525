// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          FeedbackForm
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import { UserFeedback } from "@rfcode/galaxy-model";
import { FormikHelpers, useFormik } from "formik";
import Joi from "joi";
import * as React from "react";
import { useContext } from "react";
import { informSuccess } from "../../Components/Toasts";
import { MatomoTrackerContext } from "../../Contexts/MatomoTrackerContextProvider";
import { TextAreaFormik } from "../../Formik/TextAreaFormik/TextAreaFormik";
import * as formikUtils from "../../Formik/formikUtils";
import api from "../../api/v1/feedback";
import ButtonWithLoaderFormik from "../Buttons/ButtonWithLoaderFormik";

export interface FeedbackFormProps {
  onSkip?: () => void;
  onSubmit?: () => void;
  promptText: string;
  skipText?: string;
  startCompressed: boolean;
}

type SubmitFeedbackFormInputValues = Partial<UserFeedback>;

const MESSAGE_LABEL = "Feedback";

const joiValidate = formikUtils.joiValidateToFormikErrors(
  Joi.object({
    mood: Joi.string().required().allow("happy", "neutral", "sad").only()
    , message: Joi.string()
      .empty("")
      .max(2048)
      .messages({
        "string.max": `${MESSAGE_LABEL} is too long`
      })
  })
);

export const FeedbackForm = (props: FeedbackFormProps): JSX.Element => {
  const { trackEvent } = useContext(MatomoTrackerContext);

  const onSubmit = async (
    values: SubmitFeedbackFormInputValues,
    helpers: FormikHelpers<SubmitFeedbackFormInputValues>
  ): Promise<void> => {
    try {
      console.log(values.message?.trim());
      trackEvent({
        name: "Feedback"
        , category: "Feedback"
        , action: "Submit feedback"
      });
      // casting with `as` is discouraged, but formik validation should protect us
      await api.submitFeedback(values as UserFeedback);
      informSuccess("Thank you for your feedback");
      if (props.onSubmit) {
        props.onSubmit();
      }
    } catch (error) {
      console.error(error);
    } finally {
      helpers.setSubmitting(false);
    }
  };

  const initialValues = React.useRef<SubmitFeedbackFormInputValues>({
    mood: undefined
    , prompt: props.promptText
    , message: undefined
  });

  // Notice: Formik can enter an infinite render/re-mount when initialValues is defined inline!
  const formik = useFormik({
    initialValues: initialValues.current
    , onSubmit
    , validate: joiValidate
  });

  const preSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    formikUtils.touchAllFields(formik);
    formik.handleSubmit(e);
  };

  /* Actual rendering */
  return (
    <div className="user-feedback">
      {props.promptText ? <div className="prompt">{props.promptText}</div> : undefined}
      <form className="std-form" onSubmit={preSubmit} onReset={formik.handleReset}>
        <section className="mood-selector">
          <div
            id="set-mood-sad"
            className={`button mood sad ${formik.values.mood === "sad" ? "selected" : ""}`}
            role="button"
            onClick={(): void => {
              formik.setFieldValue("mood", "sad").catch(console.error);
            }}
          >
            <span className="material-icons">sentiment_very_dissatisfied</span>
          </div>
          <div
            id="set-mood-neutral"
            className={`button mood neutral ${formik.values.mood === "neutral" ? "selected" : ""}`}
            role="button"
            onClick={(): void => {
              formik.setFieldValue("mood", "neutral").catch(console.error);
            }}
          >
            <span className="material-icons">sentiment_neutral</span>
          </div>
          <div
            id="set-mood-happy"
            className={`button mood happy ${formik.values.mood === "happy" ? "selected" : ""}`}
            role="button"
            onClick={(): void => {
              formik.setFieldValue("mood", "happy").catch(console.error);
            }}
          >
            <span className="material-icons">sentiment_very_satisfied</span>
          </div>
        </section>
        {!props.startCompressed || formik.values.mood ? (
          <>
            <section className="message">
              <TextAreaFormik
                formik={formik}
                name="message"
                label="Additional Comments"
                showCount={{ max: 2048 }}
              />
            </section>
            <div className="form-actions">
              {props.onSkip ? (
                <button type="button" className={"btn btn-outline btn-top"} onClick={props.onSkip}>
                  {props.skipText || "Cancel"}
                </button>
              ) : undefined}
              <ButtonWithLoaderFormik
                text="Submit feedback"
                formik={formik}
                className={`btn btn-primary submit btn-top${
                  formik.isSubmitting ? " submitting" : ""
                }`}
              />
            </div>
          </>
        ) : undefined}
      </form>
    </div>
  );
};
