// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2023. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          ErrorFallback
//
// Written By:          Sushil Joshi
// ------------------------------ tabstop = 2 ----------------------------------

import { useSession } from "../../hooks/Auth/useSession";

const ErrorFallback = (): JSX.Element => {
  const { logout } = useSession();

  return (
    <div>
      <p>
        Oops, something went wrong. Try{" "}
        <button
          onClick={() => {
            void logout();
          }}
        >
          logging out
        </button>{" "}
        and logging in again...
      </p>
      <p>Need Help? Contact Us at</p>
      <a
        className="btn btn-primary submit contact-us-btn"
        href={`mailto:help@rfcode.com?subject=${encodeURIComponent("Sentry Support")}`}
      >
        help@rfcode.com
      </a>
    </div>
  );
};

export default ErrorFallback;
