// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2023. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Component Name:      BackButtonContext
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { findRoutesForPath, GalaxyRouteInfo, GalaxyRoutes } from "../Routes/GalaxyRoutes";

// @ts-expect-error b/c BackButtonInfo will be initialized shortly
export const BackButtonContext = React.createContext<BackButtonInfo>(undefined);

export interface BackButtonInfo {
  showBackButton: boolean;
  /**
   * In-app navigation to the previous page, or up the route tree if the navigation session is fresh.
   */
  goBack: () => void;
  /**
   * In-app navigation to the application dashboard.
   */
  goToDashboard: () => void;
  /**
   * A friendly label for the destination of the `goBack` operation.
   */
  getBackLabel: () => string;
  /**
   * Reset in-app history stack; to be used only when a navigation event is disruptive
   * to an end user's mental map of the app.
   * @warn Be careful when calling this method.
   */
  clearHistory: () => void;
}

type HistoricRouteInfo = GalaxyRouteInfo & {
  /**
   * React Router paths include syntax like `:id` to represent ids at runtime.
   * `actualUrl` includes specific instances of those identifiers.
   */
  actualUrl: string;
  /**
   * All historic routes are labeled with either the hardcoded value from GalaxyRoutes
   * or a user-provided name for the resource. (e.g. location name, email, etc)
   */
  label: string;
};

/**
 * Stateful source of underlying data for in-app back button. Browser back button is unavailable
 * in the context of the mobile phone wrapper application!
 *
 * @note We must use React Context to continuously track the in-app history.
 * A plain hook might go out of scope and lose its state.
 *
 * @warn Browsers restrict access to the actual history stack, so we can only do
 * our best to track application history. (For example, refreshing the page kills in-app history.)
 *
 * @warn We perform some sleight-of-hand to hide some awkwardness of app navigation.
 */
export const BackButtonContextProvider = (props): JSX.Element => {
  const history = useHistory();
  const { pathname: currentPathname, search: currentSearch } = useLocation();

  const [previousRoutes, setPreviousRoutes] = React.useState<HistoricRouteInfo[]>([
    getHistoricRouteForPath(currentPathname, currentSearch)
  ]);

  React.useEffect(() => {
    // important: returns unlisten function for React auto-cleanup!
    return history.listen((destination) => {
      setPreviousRoutes((existing) => {
        let updated: HistoricRouteInfo[] = existing;
        const destinationRoute = getHistoricRouteForPath(destination.pathname, destination.search);

        if (shouldResetHistory(destinationRoute.path)) {
          return [destinationRoute];
        }

        switch (history.action) {
          case "POP":
            updated = existing.slice(0, -1);
            break;
          case "PUSH":
            updated = [...existing, destinationRoute];
            break;
          case "REPLACE":
            updated = [...existing.slice(0, -1), destinationRoute];
            // remove trailing duplicates for succinct history (relevant for pattern of push-replace-push-replace...)
            while (
              updated.length > 1 &&
              updated[updated.length - 1]?.actualUrl === updated[updated.length - 2]?.actualUrl
            ) {
              updated.pop();
            }
            break;
        }

        return updated;
      });
    });
  }, [history, setPreviousRoutes]);

  const [info, setInfo] = React.useState<BackButtonInfo>({
    getBackLabel: () => "Back"
    , goBack: () => {
      /* no-op while initializing, see useEffect */
    }
    , goToDashboard: () => {
      /* no-op while initializing, see useEffect */
    }
    , clearHistory: () => {
      /* no-op while initializing, see useEffect */
    }
    , showBackButton: false
  });

  React.useEffect(() => {
    const goBack = (): void => {
      if (previousRoutes.length > 1) {
        history.goBack();
      } else {
        history.replace(getHistoricRouteUpTree(currentPathname).actualUrl);
      }
    };

    const goToDashboard = (): void => {
      history.push(GalaxyRoutes.DASHBOARD.path);
    };

    const getBackLabel = (): string => {
      const previousRoute =
        previousRoutes[previousRoutes.length - 2] ?? getHistoricRouteUpTree(currentPathname);
      return previousRoute.label || "Back";
    };

    setInfo({
      getBackLabel
      , goBack
      , goToDashboard
      , clearHistory: () => setPreviousRoutes([])
      , showBackButton: shouldShowBackButton(currentPathname)
    });
  }, [currentPathname, previousRoutes, history]);

  return <BackButtonContext.Provider value={info}>{props.children}</BackButtonContext.Provider>;
};

/**
 * Attempts to find a valid GalaxyRoute for the provided pathname, or the
 * default Dashboard route if no good match is found.
 */
function getHistoricRouteForPath(pathname: string, search: string): HistoricRouteInfo {
  const split = pathname.split("/");

  let bestMatch: HistoricRouteInfo = {
    ...GalaxyRoutes.DASHBOARD
    , actualUrl: GalaxyRoutes.DASHBOARD.path
    , label: GalaxyRoutes.DASHBOARD.label || "" // We know dashboard label exists
  };

  // Iteratively strip final portion of the path until one matches, example:
  // first try: "/locations/My%20DLocation/assets" (no match)
  // next try: "/locations/My%20DLocation" (match!)
  for (let finalIndex = split.length; finalIndex > 0; finalIndex -= 1) {
    const possiblePath = split.slice(0, finalIndex).join("/");
    const routeMatches = findRoutesForPath(possiblePath);
    if (routeMatches.length > 0) {
      // https://www.semrush.com/blog/what-is-a-url-slug/
      const slug = decodeURIComponent(possiblePath.split("/").slice(-1)[0]);
      bestMatch = {
        ...routeMatches[0]
        , actualUrl: possiblePath + search
        , label: routeMatches[0].label || slug
      };
      break; // short-circuit once we find a good one
    }
  }

  // clone so caller can do whatever they want without breaking stuff
  return bestMatch;
}

/**
 * Find the nearest route up the navigation tree for the given path.
 */
function getHistoricRouteUpTree(pathname: string): HistoricRouteInfo {
  const trimmedPathname = pathname.split("/").slice(0, -1).join("/");
  return getHistoricRouteForPath(trimmedPathname, "");
}

/**
 * Determine whether the current location of the app justifies a reset of history.
 * We reset history whenever the user visits an navigation "root".
 */
function shouldResetHistory(pathname: string): boolean {
  // warning: this implementation only works for paths that are hardcoded
  // "/locations/My%20DLocation" _won't_ work because its real path is "/locations/:id"
  switch (pathname) {
    case GalaxyRoutes.ADMINISTRATION.path:
    case GalaxyRoutes.DASHBOARD.path:
    case GalaxyRoutes.ALT_GRID_DASHBOARD.path:
    case GalaxyRoutes.ORG_SETUP.path:
    case GalaxyRoutes.WELCOME_NOTIFICATIONS.path:
      return true;
    default:
      return false;
  }
}

function shouldShowBackButton(pathname: string): boolean {
  return !shouldResetHistory(pathname);
}
