// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:      formikUtils.ts
//
// Written By:          Richard Salcedo
// ------------------------------ tabstop = 2 ----------------------------------

import Joi from "joi";
import { FormikErrors, FormikProps } from "formik";

/**
 * Formik needs an error object with the same shape as the form values. It expects that
 * if there is an error on any attribute in the form, there will be a corresponding
 * attribute with an error message in the error object.
 * @param schema A Joi schema object
 * @returns A callback that Formik can use for validation
 */
export const joiValidateToFormikErrors = <FormValuesType>(
  schema: Joi.ObjectSchema
): ((values: FormValuesType) => FormikErrors<any>) => {
  return (values: FormValuesType): FormikErrors<any> => {
    // TODO revisit allowUnknown
    const validation: Joi.ValidationResult = schema.validate(values, {
      allowUnknown: true
      , abortEarly: false
    });
    let validationErrors = {};
    if (validation.error) {
      validationErrors = validation.error.details.reduce((validationErrors, detail) => {
        if (detail.context?.key) {
          validationErrors[detail.context.key] = detail.message;
        }
        return validationErrors;
      }, {});
    }
    return validationErrors;
  };
};

/**
 * @param formik The formik object being used
 * @param validateOnTouch Option to run validation after each field is touched. This is false by default (for us).
 */
export const touchAllFields = (formik: FormikProps<any>, validateOnTouch = false): void => {
  for (const key in formik.values) {
    formik.setFieldTouched(key, true, validateOnTouch);
  }
};
