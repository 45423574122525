// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2022-2023. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          Footer
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import { DateTime } from "luxon";
import React from "react";

const Footer = (): JSX.Element => (
  <footer id="footer">
    <div className="copyright">{`©${DateTime.utc().year} RF Code`}</div>
  </footer>
);

export default Footer;
