// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2021. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Component Name:      MatomoTracker
//
// Written By:          Sushil Joshi
// ------------------------------ tabstop = 2 ----------------------------------

type MatomoTrackerOptions = {
  baseUrl: string;
  trackerPath: string;
  scriptPath: string;
  siteId: string;
  heartBeatTimer: number;
  userId?: string;
};

type TrackPageViewArgs = {
  documentTitle: string;
  currentUrl: string;
};

type TrackEventArgs = {
  category: string;
  action: string;
  name?: string;
  value?: number;
};

/**
 * Merge declaration of type Window by adding property _paq, which is expected
 * by matomo in global scope
 */
declare global {
  interface Window {
    _paq: [string, ...any[]][];
  }
}

export class MatomoTracker {
  /**
   * Construct matomo instance and initialize it by setting script node to point to
   * javascript deployed at matomo server.
   * The initialization code is adapted from "Javascript Tracking Code" which is autogenerated
   * by matomo server after a site is created. The modification is basically for readability.
   *
   * @param options
   */
  constructor(options?: MatomoTrackerOptions) {
    if (!options) {
      return;
    }
    const _paq = (window._paq = window._paq || []);
    _paq.push(["enableHeartBeatTimer", options.heartBeatTimer]);
    _paq.push(["setRequestMethod", "POST"]);
    _paq.push(["setRequestContentType", "application/x-www-form-urlencoded"]);
    if (options.userId) {
      _paq.push(["setUserId", options.userId]);
    }

    (function () {
      // Appending idsite as url parameter enables matomo server to understand a preflight request for
      // heatmap and session recording XHR. Typically, a beacon is sent as a POST method, but that has
      // a limitation of 65KB. The tracker javascript attempts XHR if beacon api call fails. That's when
      // a preflight is sent. Without idsite parameter, the preflight fails with 400 status.
      _paq.push([
        "setTrackerUrl"
        , `${options.baseUrl}/${options.trackerPath}?idsite=${options.siteId}`
      ]);
      _paq.push(["setSiteId", options.siteId]);

      const newScriptNode = document.createElement("script");
      newScriptNode.type = "text/javascript";
      newScriptNode.async = true;
      newScriptNode.src = `${options.baseUrl}/${options.scriptPath}`;

      const existingScriptNode = document.getElementsByTagName("script")[0];
      if (existingScriptNode?.parentNode) {
        existingScriptNode.parentNode.insertBefore(newScriptNode, existingScriptNode);
      } else {
        const headNode = document.getElementsByTagName("head")[0];
        headNode.appendChild(newScriptNode);
      }
    })();
  }

  /**
   * Wrapper around matomo trackPageView instruction.
   */
  trackPageView = ({ documentTitle, currentUrl }: TrackPageViewArgs) => {
    this.push(["setCustomUrl", currentUrl]);
    this.push(["trackPageView", documentTitle]);
  };

  /**
   * Wrapper around matomo trackEvent instruction.
   */
  trackEvent = ({ category, action, name, value }: TrackEventArgs) => {
    this.push(["trackEvent", category, action, name, value]);
  };

  /**
   * Access to basic matomo api to push instructions to queue
   * @param instruction
   */
  push = (instruction: [string, ...any[]]) => {
    if (window._paq) {
      window._paq.push(instruction);
    }
  };
}
