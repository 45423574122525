// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2021. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Component Name:      MatomoTrackerContextProvider
//
// Written By:          Sushil Joshi
// ------------------------------ tabstop = 2 ----------------------------------

import React, { useContext } from "react";
import { useSession } from "../hooks/Auth/useSession";
import { MatomoTracker } from "../MatomoTracker";

const emptyMatomoTrackerInstance = new MatomoTracker();
export const MatomoTrackerContext = React.createContext<MatomoTracker>(emptyMatomoTrackerInstance);

export const MatomoTrackerContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const { profile } = useSession();
  const matomoTracker = new MatomoTracker({
    baseUrl: "https://track.rfcode.com"
    , siteId: "1"
    , trackerPath: "matomo.php"
    , scriptPath: "matomo.js"
    , heartBeatTimer: 10
    , userId: profile?.email
  });
  return (
    <MatomoTrackerContext.Provider value={matomoTracker}>
      {props.children}
    </MatomoTrackerContext.Provider>
  );
};
