// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2023. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          GalaxyRoutes.tsx
//
// Written By:          Beau Anderson
// ------------------------------ tabstop = 2 ----------------------------------

import { hasAdmin, hasAnyPermissions, hasMetrics, UserProfile } from "@rfcode/galaxy-model";
import React from "react";
import type { RouteProps } from "react-router-dom";
import { matchPath } from "react-router-dom";
import { withTracking } from "./withTracking";

/**
 * Additional attributes used by Galaxy application logic.
 * @see RouteProps
 * @see GalaxyRoutes
 */
type GalaxyRouteDecorations = {
  /**
   * Browser URL that renders this route.
   * @example `/reset-password/:token`
   * @warn Overrides ReactRouter to prevent string[] complexity
   */
  path: string;
  /**
   * If present, friendly name to use for links to this route.
   */
  label?: string;
  /**
   * If true, users may access this route while unauthenticated
   */
  unauthenticated?: boolean;
  /**
   * If true, user must have admin privileges to access this route.
   */
  requiresAdmin?: boolean;
  /**
   * If true, user must have usage privileges to access this route.
   */
  requiresMetrics?: boolean;
  /**
   * If present, user must belong
   */
  onlyAdminTenant?: boolean;
  /**
   * If present, icon to use when rendering links to this route.
   */
  materialIcon?: string;
  /**
   * If present, custom class to apply to `div.nav-header` when route is active.
   */
  navHeaderClass?: string;
  /**
   * If present, custom page title to apply to window.
   */
  pageTitle?: string;
  /**
   * If present, string to append to route label given sufficient space.
   */
  labelSuffix?: string;
  /**
   * If present, order of route in top-level application navigation.
   */
  sortOrder?: number;
  /**
   * If present, user must belong to a tenant used for demos
   */
  onlyDemoTenant?: boolean;
};

/**
 * @param route
 * @param profile
 * @returns whether the given GalaxyRoute is available to the current user
 * @see GalaxyRoutes
 */
export function isRouteAvailable(
  route: GalaxyRouteInfo,
  profile: UserProfile | null | undefined
): boolean {
  let hasPermission = false;
  if (route.requiresAdmin) {
    hasPermission = !!profile && hasAdmin(profile);
  } else if (route.requiresMetrics) {
    hasPermission = !!profile && hasMetrics(profile);
  } else {
    // unauthenticated routes are always available
    // otherwise make sure profile exists from valid session
    hasPermission = route.unauthenticated || !!profile;
  }
  let isCorrectTenant = true;
  if (route.onlyAdminTenant) {
    isCorrectTenant = profile?.tenant === "galaxy-admin-tenant";
  }
  if (route.onlyDemoTenant) {
    //Adding tenants from staging. The goal is to use this whitelist to prevent the demo page from being exposed in prod
    isCorrectTenant = [
      "f6eb43a7-e28c-4b39-b848-f2f6a8deb20d"
      , "d476d4da-ba44-4248-8c9e-4b05f84130e4"
      , "63e59cb3-c259-4163-805c-8bad5f765815"
      , "20363c32-afcb-4373-9797-89b3e6a7b98a"
    ].includes(profile?.tenant ?? "");
  }
  return hasPermission && isCorrectTenant;
}

/**
 * @param pathname
 * @returns list of GalaxyRoutes that match this pathname
 * @see GalaxyRoutes
 */
export function findRoutesForPath(pathname: string): GalaxyRouteInfo[] {
  return Object.values(GalaxyRoutes).filter((route) =>
    matchPath(pathname, { exact: true, ...route })
  );
}

/**
 * Well-known keys used to index GalaxyRoutes dictionary.
 * @see GalaxyRoutes
 */
export type GalaxyRouteId =
  | "ADMINISTRATION"
  | "CHANGE_PASSWORD"
  | "DASHBOARD"
  | "DEMO_AUTOMATION"
  | "ALT_GRID_DASHBOARD"
  | "FORGOT_PASSWORD"
  | "GOODBYE"
  | "HELP"
  | "INSTALLATION_NETWORK_SETTINGS"
  | "INSTALLATION_POLICIES"
  | "INSTALLATION_POLICY_DETAILS"
  | "LIVE_PLAYBACK"
  | "LOCATION_ADDRESS"
  | "LOCATION_ALERT_ACTIVITY_DETAIL"
  | "LOCATION_ASSET_FORM"
  | "LOCATION_CALENDAR"
  | "LOCATION_CREATE"
  | "LOCATION_TODAY"
  | "LOCATION_DAY_HISTORY"
  | "LOCATION_HEALTH"
  | "LOCATION_SETTINGS"
  | "LOCATION_RENAME"
  | "LOCATION_DEVICES"
  | "LOG_SETTINGS"
  | "METRICS"
  | "METRICS_SENTRIES"
  | "MY_NOTIFICATIONS"
  | "MY_PROFILE"
  | "NOTIFICATIONS"
  | "ORG_SETUP"
  | "POLICIES"
  | "POLICY_DETAILS"
  | "POLICY_OVERRIDE_LOCATIONS"
  | "RESET_PASSWORD"
  | "SENTRY_SETUP"
  | "SIGN_IN"
  | "SIGN_UP"
  | "TENANTS"
  | "TENANT_CREATE"
  | "UPDATES"
  | "USERS"
  | "USER_CREATE"
  | "USER_DETAILS"
  | "USER_TYPE_SELECT"
  | "VERSION"
  | "WELCOME_NOTIFICATIONS";

/**
 * Extend react-router props with our custom ones.
 * @see GalaxyRoutes
 */
export type GalaxyRouteInfo = RouteProps & GalaxyRouteDecorations;

/**
 * Dictionary of available routes & pages for use by React Router.
 */
export const GalaxyRoutes: { [key in GalaxyRouteId]: GalaxyRouteInfo } = {
  SIGN_IN: {
    path: "/sign-in"
    , component: withTracking(React.lazy(() => import("./SignIn")))
    , label: "Sign In"
    , unauthenticated: true
  }
  , SIGN_UP: {
    path: "/sign-up/:token"
    , component: withTracking(React.lazy(() => import("./SignUp")))
    , label: "Sign Up"
    , unauthenticated: true
  }
  , RESET_PASSWORD: {
    path: "/reset-password/:token"
    , component: withTracking(React.lazy(() => import("./SetPassword")))
    , label: "Reset Password"
    , unauthenticated: true
  }
  , FORGOT_PASSWORD: {
    path: "/forgot-password"
    , component: withTracking(React.lazy(() => import("./ForgotPassword")))
    , label: "Forgot Password"
    , unauthenticated: true
  }
  , VERSION: {
    path: "/version"
    , component: withTracking(React.lazy(() => import("./Version")))
    , label: "Version"
    , unauthenticated: true
  }
  , GOODBYE: {
    path: "/goodbye"
    , component: withTracking(React.lazy(() => import("./Goodbye")))
    , label: "Goodbye"
    , unauthenticated: true
  }
  , ADMINISTRATION: {
    path: "/admin"
    , label: "Admin"
    , pageTitle: "Administration"
    , component: withTracking(React.lazy(() => import("./Administration")))
    , materialIcon: "admin_panel_settings"
    , labelSuffix: "istration"
    , sortOrder: 200
  }
  , DASHBOARD: {
    path: "/locations"
    , label: "Locations"
    , pageTitle: "Locations"
    , component: withTracking(React.lazy(() => import("./Locations")))
    , materialIcon: "place"
    , navHeaderClass: "locations-page"
    , sortOrder: 100
  }
  , ALT_GRID_DASHBOARD: {
    path: "/_/locations"
    , component: withTracking(React.lazy(() => import("./AltGridDashboard")))
    , materialIcon: "place"
    , navHeaderClass: "locations-page"
  }
  , LIVE_PLAYBACK: {
    path: "/locations/:id/live"
    , label: "Live Playback"
    , pageTitle: "Live Playback"
    , component: withTracking(React.lazy(() => import("./LivePlayback")))
    , materialIcon: "play"
  }
  , LOCATION_CREATE: {
    path: "/location/create"
    , label: "Create Location"
    , pageTitle: "Create Location"
    , component: withTracking(React.lazy(() => import("./LocationCreate")))
    , requiresAdmin: true
  }
  , LOCATION_HEALTH: {
    path: "/locations/:id"
    , pageTitle: "Location Dashboard"
    , component: withTracking(React.lazy(() => import("./LocationPage")))
  }
  , LOCATION_ASSET_FORM: {
    path: "/locations/:id/asset/:asset?"
    , label: "Asset"
    , pageTitle: "Asset"
    , component: withTracking(React.lazy(() => import("./AssetPage")))
    , requiresAdmin: true
  }
  , LOCATION_CALENDAR: {
    path: "/locations/:id/calendar"
    , label: "Calendar"
    , pageTitle: "Calendar"
    , component: withTracking(React.lazy(() => import("./LocationCalendar")))
    , materialIcon: "calendar_today"
  }
  , LOCATION_DAY_HISTORY: {
    path: "/locations/:id/history"
    , label: "Telemetry Graph"
    , pageTitle: "Telemetry Graph"
    , component: withTracking(React.lazy(() => import("./LocationDayHistory")))
  }
  , LOCATION_TODAY: {
    path: "/locations/:id/today"
    , label: "Last 24 hours"
    , pageTitle: "Last 24 hours"
    , component: withTracking(React.lazy(() => import("./LocationDayHistory")))
  }
  , LOCATION_ALERT_ACTIVITY_DETAIL: {
    path: "/locations/:id/alert/:alertId"
    , label: "Alert Details"
    , pageTitle: "Alert Details"
    , component: withTracking(React.lazy(() => import("./ActivityPage")))
  }
  , LOCATION_SETTINGS: {
    path: "/locations/:id/settings"
    , label: "Location Settings"
    , pageTitle: "Location Settings"
    , component: withTracking(React.lazy(() => import("./LocationSettings")))
    , materialIcon: "settings"
  }
  , LOCATION_RENAME: {
    path: "/locations/:id/settings/name"
    , label: "Location Name"
    , pageTitle: "Location Name"
    , component: withTracking(React.lazy(() => import("./LocationRename")))
    , requiresAdmin: true
    , materialIcon: "edit"
  }
  , LOCATION_ADDRESS: {
    path: "/locations/:id/settings/address"
    , label: "Location Address"
    , pageTitle: "Location Address"
    , component: withTracking(React.lazy(() => import("./LocationAddress")))
    , requiresAdmin: true
    , materialIcon: "location_on"
  }
  , LOCATION_DEVICES: {
    path: "/locations/:id/settings/devices"
    , label: "Installed Devices"
    , pageTitle: "Installed Devices"
    , component: withTracking(React.lazy(() => import("./LocationDevices")))
    , requiresAdmin: false
    , materialIcon: "sensors"
  }
  , INSTALLATION_NETWORK_SETTINGS: {
    path: "/locations/:id/settings/devices/:deviceId/network"
    , label: "Network Settings"
    , pageTitle: "Network Settings"
    , component: withTracking(React.lazy(() => import("./NetworkSettings")))
    , requiresAdmin: true
    , materialIcon: "wifi"
  }
  , INSTALLATION_POLICIES: {
    path: "/locations/:id/settings/devices/:deviceId/policies"
    , label: "Policy Overrides"
    , pageTitle: "Policy Overrides"
    , component: withTracking(React.lazy(() => import("./InstallationPolicies")))
    , requiresAdmin: false
    , materialIcon: "policy"
  }
  , INSTALLATION_POLICY_DETAILS: {
    path: "/locations/:id/settings/devices/:deviceId/policies/:policyId"
    , pageTitle: "Policy Override"
    , component: withTracking(React.lazy(() => import("./InstallationPolicyDetails")))
    , requiresAdmin: false
  }
  , LOG_SETTINGS: {
    path: "/admin/logs"
    , label: "Logs"
    , pageTitle: "Log Settings"
    , component: withTracking(React.lazy(() => import("./LogSettings")))
    // technically only the admin tenant can access this
    , requiresAdmin: true
  }
  , ORG_SETUP: {
    path: "/admin/setup"
    , label: "Organization Setup"
    , pageTitle: "Setup"
    , component: withTracking(React.lazy(() => import("./OrganizationSetup")))
    , requiresAdmin: true
  }
  , POLICIES: {
    path: "/admin/policies"
    , label: "Policies"
    , pageTitle: "Policies"
    , component: withTracking(React.lazy(() => import("./Policies")))
    , materialIcon: "policy"
  }
  , POLICY_DETAILS: {
    path: "/admin/policies/:id"
    , pageTitle: "Policy Settings"
    , component: withTracking(React.lazy(() => import("./PolicyDetails")))
  }
  , POLICY_OVERRIDE_LOCATIONS: {
    path: "/admin/policies/:id/overrides"
    , label: "Policy Overrides"
    , pageTitle: "Policy Overrides"
    , component: withTracking(React.lazy(() => import("./PolicyOverrideLocations")))
    , requiresAdmin: true
  }
  , NOTIFICATIONS: {
    path: "/admin/users/:id/notifications"
    , label: "Notification Preferences"
    , pageTitle: "Notification Preferences"
    , component: withTracking(React.lazy(() => import("./NotificationPreferencesPage")))
    , requiresAdmin: true
    , materialIcon: "notifications"
  }
  , MY_NOTIFICATIONS: {
    path: "/profile/notifications"
    , label: "Notification Preferences"
    , pageTitle: "Notification Preferences"
    , component: withTracking(React.lazy(() => import("./MyNotificationPreferencesPage")))
    , materialIcon: "notifications"
  }
  , WELCOME_NOTIFICATIONS: {
    path: "/welcome/notifications"
    , label: "Notification Preferences"
    , pageTitle: "Notification Preferences"
    , component: withTracking(React.lazy(() => import("./WelcomeNotifications")))
  }
  , SENTRY_SETUP: {
    path: "/locations/:id/setup"
    , label: "Sentry Setup"
    , pageTitle: "Sentry Setup"
    , component: withTracking(React.lazy(() => import("./SentrySetup")))
    , requiresAdmin: true
    , materialIcon: "sensors_off"
  }
  , USERS: {
    path: "/admin/users"
    , label: "Users"
    , pageTitle: "Users"
    , component: withTracking(React.lazy(() => import("./Users")))
    , requiresAdmin: true
    , materialIcon: "group"
  }
  , TENANTS: {
    path: "/admin/tenants"
    , label: "Tenants"
    , pageTitle: "Tenants"
    , component: withTracking(React.lazy(() => import("./Tenants")))
    , requiresAdmin: true
    , onlyAdminTenant: true
    , materialIcon: "switch_account"
  }
  , MY_PROFILE: {
    path: "/profile"
    , label: "Profile"
    , pageTitle: "Profile"
    , component: withTracking(React.lazy(() => import("./MyProfile")))
    , materialIcon: "account_circle"
  }
  , CHANGE_PASSWORD: {
    path: "/profile/change-password"
    , label: "Change Password"
    , pageTitle: "Change Password"
    , component: withTracking(React.lazy(() => import("./ChangePassword")))
    , materialIcon: "vpn_key"
  }
  , USER_DETAILS: {
    path: "/admin/users/:id"
    , pageTitle: "User Details"
    , component: withTracking(React.lazy(() => import("./UserDetails")))
    , requiresAdmin: true
  }
  , USER_TYPE_SELECT: {
    path: "/admin/create-user"
    , label: "Create User"
    , pageTitle: "Users"
    , component: withTracking(React.lazy(() => import("./UserTypeSelect")))
    , requiresAdmin: true
    , materialIcon: "person_add"
  }
  , USER_CREATE: {
    path: "/admin/create-user/:type"
    , label: "Create User"
    , pageTitle: "Users"
    , component: withTracking(React.lazy(() => import("./UserCreate")))
    , requiresAdmin: true
    , materialIcon: "person_add"
  }
  , TENANT_CREATE: {
    path: "/admin/create-tenant"
    , label: "Create Tenant"
    , pageTitle: "Tenants"
    , component: withTracking(React.lazy(() => import("./TenantCreate")))
    , requiresAdmin: true
    , onlyAdminTenant: true
    , materialIcon: "group_add"
  }
  , HELP: {
    // note: external links cannot use <Link/> elements
    path: "https://sentryhelp.rfcode.com"
    , label: "Help"
    , pageTitle: "Help"
    , materialIcon: "help"
  }
  , UPDATES: {
    // note: external links cannot use <Link/> elements
    path: "https://sentryhelp.rfcode.com/blog"
    , label: "Updates"
    , pageTitle: "Updates"
    , materialIcon: "Updates"
  }
  , METRICS: {
    path: "/metrics"
    , label: ""
    , pageTitle: ""
    , component: React.lazy(() => import("./Metrics"))
    , requiresMetrics: true
    , onlyAdminTenant: true
    , materialIcon: "analytics"
  }
  , METRICS_SENTRIES: {
    path: "/metrics/sentries"
    , label: "Sentry Metrics"
    , pageTitle: "Sentry Metrics"
    , component: React.lazy(() => import("./MetricsForSentries"))
    , requiresMetrics: true
    , onlyAdminTenant: true
    , materialIcon: "rule"
  }
  , DEMO_AUTOMATION: {
    path: "/locations/:id/demo"
    , label: "Demo Automation"
    , pageTitle: "Demo Automation"
    , component: withTracking(React.lazy(() => import("./DemoPage")))
    , requiresAdmin: true
    , materialIcon: "analytics"
    , onlyDemoTenant: true
  }
} as const;
