//------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          api/v1/feedback
//
// Written By:          Patrick Stewart
//------------------------------ tabstop = 2 ----------------------------------

import { UserFeedback } from "@rfcode/galaxy-model";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { COMMON_HEADERS } from "./common";

async function submitFeedback(feedback: UserFeedback): Promise<AxiosResponse<void>> {
  const data: UserFeedback = {
    ...feedback
    , message: feedback.message?.trim()
  };
  const config: AxiosRequestConfig = {
    method: "POST"
    , url: "/api/v1/feedback"
    , headers: {
      ...COMMON_HEADERS
    }
    , data
  };
  return axios(config);
}

const api = {
  submitFeedback
};

export default api;
