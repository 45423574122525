// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Component Name:      TextAreaFormik
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import { FormikProps } from "formik";
import * as React from "react";

export interface TextAreaFormikProps {
  formik: FormikProps<any>;
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  placeholder?: string;
  help?: string;
  showCount?: { max: number };
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleBlur?: (e: unknown) => void;
}

export const TextAreaFormik = (props: TextAreaFormikProps): JSX.Element => {
  const { formik, name, label, hidden, required, disabled, showCount, help, placeholder } = props;

  const value = formik.values[name] || "";
  const error = formik.errors[name];
  const touched = formik.touched[name];

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    formik.handleChange(e);
    if (props.handleChange) props.handleChange(e);
  };

  const handleBlur = (e: unknown): void => {
    formik.handleBlur(e);
    if (props.handleBlur) props.handleBlur(e);
  };

  const displayError = touched && error && typeof error === "string";
  const countError = showCount ? value.length > showCount.max : false;

  const inputClasses = `input${disabled ? " disabled" : ""}${displayError ? " input-error" : ""}`;
  const showCountClasses = `input-validation-count${countError ? "-error" : ""}`;

  return (
    <div className={`input-container${hidden ? " hidden-input" : ""}`}>
      <div className="text-area-label">
        {label && <div className="input-label">{label}</div>}
        {showCount && <div className={showCountClasses}>{`${value?.length}/${showCount.max}`}</div>}
      </div>
      <div className="input-text-container">
        <textarea
          id={name}
          name={name ?? ""}
          value={value ?? ""}
          placeholder={placeholder ?? ""}
          disabled={disabled ?? false}
          hidden={hidden ?? false}
          required={required ?? false}
          className={inputClasses}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      {displayError && <div className="input-validation-error">{error}</div>}
      {help && !displayError && <div className="input-validation-help">{help}</div>}
    </div>
  );
};
