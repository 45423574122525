// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          httpMethods.ts
//
// Written By:          Patrick Stewart / Daniel Savage
// ------------------------------ tabstop = 2 ----------------------------------

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
 *
 * GET     - The GET method requests a representation of the specified resource. Requests using GET should only retrieve data.
 *
 * HEAD    - The HEAD method asks for a response identical to that of a GET request, but without the response body.
 *
 * POST    - The POST method is used to submit an entity to the specified resource, often causing a change in state or side effects on the server.
 *
 * PUT     - The PUT method replaces all current representations of the target resource with the request payload.
 *
 * DELETE  - The DELETE method deletes the specified resource.
 *
 * CONNECT - The CONNECT method establishes a tunnel to the server identified by the target resource.
 *
 * OPTIONS - The OPTIONS method is used to describe the communication options for the target resource.
 *
 * TRACE   - The TRACE method performs a message loop-back test along the path to the target resource.
 *
 * PATCH   - The PATCH method is used to apply partial modifications to a resource.
 */
export type HttpMethod =
  | "GET"
  | "HEAD"
  | "POST"
  | "PUT"
  | "DELETE"
  // | "CONNECT" // unsupported by axios
  | "OPTIONS"
  // | "TRACE" // unsupported by axios
  | "PATCH";

/**
 * Helper function for ensuring that axios returns success for our expected status codes.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 */
export function validateStatus(status: number): boolean {
  return (status >= 200 && status < 300) || status === 304;
}

/**
 * HTTP headers commonly used with the Galaxy server.
 */
export const COMMON_HEADERS = {
  Accept: "application/json"
  , "Content-Type": "application/json"
  , credentials: "same-origin"
  // Uncacheable endpoints must specify this explicitly
  // , "cache-control": "no-cache"
} as const;
