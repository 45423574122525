// ------------------------------ tabstop = 2 ----------------------------------
// Copyright (C) 2020-2022. RFCode, Inc.
//
// All rights reserved.
//
// This software is protected by copyright laws of the United States
// and of foreign countries. This material may also be protected by
// patent laws of the United States and of foreign countries.
//
// This software is furnished under a license agreement and/or a
// nondisclosure agreement and may only be used or copied in accordance
// with the terms of those agreements.
//
// The mere transfer of this software does not imply any licenses of trade
// secrets, proprietary technology, copyrights, patents, trademarks, or
// any other form of intellectual property whatsoever.
//
// RFCode, Inc. retains all ownership rights.
//
// -----------------------------------------------------------------------------
//
// Class Name:          useSession.ts
//
// Written By:          Patrick Stewart
// ------------------------------ tabstop = 2 ----------------------------------

import { LoginRequest, LoginTenantChallenge, UserProfile } from "@rfcode/galaxy-model";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import * as authApi from "../../api/v1/auth";
import * as userApi from "../../api/v1/users";

export interface UseSession {
  isLoading: boolean;
  isError: boolean;
  profile: UserProfile | null;
  login: (data: LoginRequest) => Promise<null | LoginTenantChallenge>;
  logout: () => Promise<void>;
  updateMyProfile: (...args: Parameters<typeof userApi.updateMyProfile>) => Promise<UserProfile>;
  deleteMyProfile: (...args: Parameters<typeof userApi.deleteMyProfile>) => Promise<void>;
}

const UseMyProfileKey = "/api/v1/my-profile";

// GAL-1897: refresh on an interval so unfocused browsers redirect to login sooner
// otherwise browsers left open on secondary monitors have failed API requests and weird behavior
const REFRESH_INTERVAL = 30000; // ms

export function useSession(): UseSession {
  const location = useLocation();
  const history = useHistory();
  const redirectToSignIn = React.useCallback(() => {
    // GAL-1897: increase redirect consistency by wrapping logic inside quick timeout-closure
    // ... I don't fully understand why it was inconsistent in the first place
    const { pathname, search } = location;
    const redirect = history.replace;
    setTimeout(() => {
      if (shouldRedirect(pathname)) {
        const searchParams = new URLSearchParams(search);
        if (!pathname.includes("sign-")) {
          searchParams.append("redirect", location.pathname);
        }
        redirect(`/sign-in?${searchParams.toString()}`);
      }
    }, 50);
  }, [location, history]);

  const [isLoading, setLoading] = React.useState(true);

  // warning: `data` doesn't exist on first render
  const { data, error, mutate } = useSWR(
    UseMyProfileKey,
    async () => {
      const { data: fetched, status } = await userApi.getMyProfile();
      if (status === 401) {
        redirectToSignIn();
        return null;
      } else {
        return fetched;
      }
    },
    {
      errorRetryInterval: 15000 // ms
      , focusThrottleInterval: REFRESH_INTERVAL // ms
      , revalidateOnFocus: true
      , revalidateOnReconnect: true
      , refreshInterval: REFRESH_INTERVAL // ms
      , dedupingInterval: REFRESH_INTERVAL // ms
      , onSuccess: () => setLoading(false)
      , onError: () => setLoading(false)
    }
  );

  const profile = data ? data : null;
  const isError = !!error;

  const login = React.useCallback<UseSession["login"]>(
    async (...args) => {
      const { data } = await authApi.login(...args);
      await mutate();
      return data;
    },
    [mutate]
  );

  const logout = React.useCallback<UseSession["logout"]>(
    async (...args) => {
      await authApi.logout(...args);
      // app checks whether user is logged in based on existence of profile
      // mutate to delete the in-memory value after logout
      await mutate(null);
    },
    [mutate]
  );

  const updateMyProfile = React.useCallback<UseSession["updateMyProfile"]>(
    async (...args) => {
      const { data } = await userApi.updateMyProfile(...args);
      await mutate(() => data);
      return data;
    },
    [mutate]
  );

  const deleteMyProfile = React.useCallback<UseSession["deleteMyProfile"]>(async (...args) => {
    await userApi.deleteMyProfile(...args);
    await authApi.logout();
  }, []);

  return {
    isLoading
    , isError
    , profile
    , login
    , logout
    , updateMyProfile
    , deleteMyProfile
  };
}

const urlsProtectedFromRedirect = [
  "/reset-password"
  , "/forgot-password"
  , "/sign-up"
  , "/sign-in"
  , "/version"
  , "/goodbye"
];
function shouldRedirect(pathname: string): boolean {
  for (const url of urlsProtectedFromRedirect) {
    if (pathname.includes(url)) {
      return false;
    }
  }
  return true;
}
